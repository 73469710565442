import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdxLayout/MdxLayout.js";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
export const query = graphql`
  query {
    sizifyButtonImg: file(relativePath: { eq: "sizifyButton.png" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`How to start using Sizify`}</h1>
    <h2>{`Go through get clothing size process`}</h2>
    <p>{`First, `}<Link className='mdxLayout-link' to="/app/signup" mdxType="Link">{` `}<strong parentName="p">{`Sign up`}</strong>{` `}</Link>{` with Sizify.`}</p>
    <p>{`Then, go through quick set-up process.`}</p>
    <p>{`Done, you’re ready to go!`}</p>
    <h2>{`Using Sizify while shopping`}</h2>
    <p>{`Firstly, the website you are shopping with must be using Sizify.`}</p>
    <p>{`Next, click `}<strong parentName="p">{`Get Size Now`}</strong>{` button.`}</p>
    <Img className="mdxLayout-img" fixed={props.data.sizifyButtonImg.childImageSharp.fixed} mdxType="Img" />
    <p>{`If you are new to Sizify, click `}<strong parentName="p">{`Sign up`}</strong>{` button, and go through set up process (~2mins).
Click `}<strong parentName="p">{`Login`}</strong>{` button, if you already have a Sizify account.`}</p>
    <p>{`Once you successfully sign up/log in, you will start receiving clothing size recommendations on that website.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      